import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image";

import { Container } from "../global"

const Header = () => {

  const handleSubmit = event => {
    event.preventDefault()
  }

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <Subtitle>Coming soon</Subtitle>
            <h1>
              Your personalized vitamins, in one package
            </h1>
            <h2>
              Reimagine your supplement intake. Receive a monthly delivery with your tailored daily supplements based on your DNA.
            </h2>
            <HeaderForm onSubmit={handleSubmit}>
              <HeaderLink href="https://6ft8552hozv.typeform.com/to/tAPxyKsG" target="_blank" rel="noopener" primary>Take the Quiz</HeaderLink>
            </HeaderForm>
            <FormSubtitle>
              Answer a few questions and get notified when we launch
            </FormSubtitle>
          </HeaderTextGroup>
          <ImageWrapper>
            <StaticImage src="../../images/hhead-pic.png" alt="A woman looking into camera" width={450} placeholder="blur" quality="90" />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  );
}

export default Header

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.background.white};
  padding-bottom: 5vh;
  clear: both;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`
const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  margin-bottom: 16px;
`

const HeaderTextGroup = styled.div`
  margin: 0;
  align-self: center;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.black.regular};
  }

  h2 {
    margin-bottom: 24px;
    color: ${props => props.theme.color.black.regular};
    ${props => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const FormSubtitle = styled.span`
  ${props => props.theme.font_size.xxsmall}
`

const HeaderLink = styled.a`
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  line-height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.primary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`
